import React from 'react'
import { object, shape, string } from 'prop-types'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import BlogRoll from '../components/BlogRoll'
import TeamRoll from '../components/TeamRoll'
// import InstagramRoll from '../components/InstagramRoll'

export const IndexPageTemplate = ({
  title,
  heading,
}) => (
  <div>
    <section className="pt5 pt6-l bg-black cover" style={{ backgroundImage: 'url(/img/prole-bg.jpg)'}}>
      <div className="tc pv4 pv6-ns ph4">
        <h1 className="mt0 mb2 f1 white o-0 absolute">
          {title}
        </h1>
        <h3 className="f4 f2-ns white mw7-ns center lh-title">
          {heading}
        </h3>
        <Link to="/about" className="ba b--white-40 br-pill bw1 white pv2 ph3 dib mt3 no-underline bg-animate hover-bg-white hover-black">
          Saiba Mais
        </Link>
      </div>
    </section>
    <section>
      <div className="mw8-ns center pa2 pa3-m pa4-l">
        <h2 className="ttu pl3 mb2 mt2">Novidades</h2>
        <BlogRoll />
      </div>
    </section>
    <section className="bg-near-white">
      <div className="mw8-ns center pa2 pa3-m pa4-l">
        <h2 className="ttu pl3 mb2 mt2">Time Prole Sports</h2>
        <TeamRoll />
      </div>
    </section>
    {/* <section>
      <div className="mw8-ns center tc pa4 flex flex-wrap justify-between">
        <h2 className="ttu pl3 mb2 mt2">Instagram</h2>
        <div className="pr3">
          <a
            href="https://www.instagram.com/prolesports/"
            className="ba pv1 ph2 b--light-gray gray ttu f7 br2 bw1 no-underline bg-animate hover-bg-blue hover-white"
            target="_blank"
            rel="noopener noreferrer"
          >
            Siga a Prole
          </a>
        </div>
        <div className="w-100">
          <InstagramRoll />
        </div>
      </div>
    </section> */}
  </div>
)

IndexPageTemplate.propTypes = {
  title: string,
  heading: string,
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        title={frontmatter.title}
        heading={frontmatter.heading}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: shape({
    markdownRemark: shape({
      frontmatter: object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        heading
      }
    }
  }
`
